import "./App.css";
import Legend from "./components/Legend";
import { Button, Fab } from "@mui/material";
import {
  DeleteIc,
  DrawIc,
  LineHIc,
  LineVIc,
  PdfIc,
  PenIc,
  TextIc,
} from "./data/Icons";
import DrawLine from "./components/DrawLine";
import React, { useState } from "react";
import SideList from "./components/SideList";
function App() {
  const [drawLine, setDrawLine] = useState(false);
  const [drawText, setDrawText] = useState(false);
  const [drawStrightLine, setDrawStrightLine] = useState(0);
  const [drawItem, setDrawItem] = useState(-1);
  const [exportImage, setExportImage] = useState(false);
  const componentRef = React.createRef();
  const linkRef = React.createRef();

  return (
    <div className="App">
      <div
        // @ts-ignore
        ref={componentRef}
        style={{
          height: "100%",
        }}
      >
        <DrawLine
          onDone={() => {
            setDrawLine(false);
            setDrawText(false);
            setDrawStrightLine(0);
            setDrawItem(-1);
          }}
          drawStrightLine={drawStrightLine}
          draw={drawLine}
          text={drawText}
          drawItem={drawItem}
          exportImage={exportImage}
          download={(img: any) => {
            setExportImage(false);
            // @ts-ignore
            linkRef.current.href = img;
            // @ts-ignore
            linkRef.current.click();
          }}
        />
        <Legend />

        <div
          style={{
            ...styles.fabLeft,
            // display: drawItem === -1 ? "flex" : "none",
          }}
        >
          <Fab
            color="primary"
            size="medium"
            style={{
              ...styles.fab,
              ...{
                background: drawText ? "#46B4FA" : "rgb(130, 205, 251)",
                boxShadow: !drawText
                  ? "none"
                  : "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
              },
            }}
            aria-label="add"
            onClick={() => {
              setDrawText((drawText) => !drawText);
              setDrawLine(false);
              setDrawItem(-1);
              setDrawStrightLine(0);
            }}
          >
            <TextIc />
          </Fab>
          <Fab
            color="primary"
            size="medium"
            aria-label="add"
            style={{
              ...styles.fab,
              background:
                drawStrightLine === 1 ? "#46B4FA" : "rgb(130, 205, 251)",
              boxShadow:
                drawStrightLine !== 1
                  ? "none"
                  : "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
              // rotate: drawStrightLine == 2 ? "90deg" : "0deg",
            }}
            onClick={() => {
              setDrawStrightLine((drawStrightLine) =>
                drawStrightLine == 0 ? 1 : 0
              );
              setDrawLine(false);
              setDrawItem(-1);
              setDrawText(false);
            }}
          >
            <LineVIc />
          </Fab>
          <Fab
            color="primary"
            size="medium"
            aria-label="add"
            style={{
              ...styles.fab,
              background:
                drawStrightLine === 2 ? "#46B4FA" : "rgb(130, 205, 251)",
              boxShadow:
                drawStrightLine !== 2
                  ? "none"
                  : "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
              // rotate: drawStrightLine == 2 ? "90deg" : "0deg",
            }}
            onClick={() => {
              setDrawStrightLine((drawStrightLine) =>
                drawStrightLine === 0 ? 2 : 0
              );
              setDrawLine(false);
              setDrawItem(-1);
              setDrawText(false);
            }}
          >
            <LineHIc />
          </Fab>
          <Fab
            color="primary"
            size="medium"
            style={{
              ...styles.fab,
              ...{
                background: drawLine ? "#46B4FA" : "rgb(130, 205, 251)",
                boxShadow: !drawLine
                  ? "none"
                  : "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
              },
            }}
            aria-label="add"
            onClick={() => {
              setDrawLine((drawLine) => !drawLine);
              setDrawStrightLine(0);
              setDrawItem(-1);
              setDrawText(false);
            }}
          >
            <DrawIc />
          </Fab>
          <Fab
            color="secondary"
            size="medium"
            style={{
              ...styles.fab,
            }}
            disabled
            aria-label="Delete"
          >
            <PenIc />
          </Fab>
          <Fab
            color="secondary"
            size="medium"
            style={styles.fabDelete}
            disabled
            aria-label="Delete"
          >
            <DeleteIc />
          </Fab>
        </div>

        <div
          style={{
            ...styles.downloadButton,
            // display: drawItem === -1 ? "flex" : "none",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={styles.dwn}
            startIcon={<PdfIc />}
            onClick={() => {
              setDrawText(false);
              setDrawLine(false);
              setDrawStrightLine(0);
              setDrawItem(-1);
              setExportImage(true);
            }}
          >
            PDF
          </Button>
          <button
            // download="file.pdf"
            // @ts-ignore
            ref={linkRef}
            style={{
              display: "none",
            }}
          />
        </div>
        <SideList setSelectedItem={(id) => setDrawItem(id)} />
      </div>
    </div>
  );
}

export default App;

interface StyleProps {
  [key: string]: React.CSSProperties;
}

const styles: StyleProps = {
  fabLeft: {
    position: "fixed",
    zIndex: 3,
    top: "30px",
    left: "260px",
  },
  fabBottom: {
    position: "fixed",
    bottom: "30px",
    left: "260px",
  },
  fab: {
    marginRight: "20px",
    backgroundColor: "rgb(130, 205, 251)",
    boxShadow: "none",
  },
  fabDelete: {
    marginRight: "20px",
    backgroundColor: "#FA5352",
    boxShadow: "none",
  },
  downloadButton: {
    position: "fixed",
    bottom: "30px",
    right: "30px",
  },
  dwn: {
    backgroundColor: "#46B4FA",
    borderRadius: 50,
    padding: "15px 30px",
  },
};

import React from "react";

export default function Legend() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>LEGEND</h1>
      <h3 style={styles.abr}>CC - Cooling contactor</h3>
      <h3 style={styles.abr}>CFC - Condenser Fan Motor</h3>
      <h3 style={styles.abr}>COMP - Compressor</h3>
      <h3 style={styles.abr}>HPS - High pressure switch</h3>
      <h3 style={styles.abr}>IFM - indoor fan motor</h3>
      <h3 style={styles.abr}>IFR - indoor fan relay</h3>
      <h3 style={styles.abr}>LPS - low pressure switch</h3>
    </div>
  );
}

interface StyleProps {
  [key: string]: React.CSSProperties;
}

const styles: StyleProps = {
  container: {
    color: "rgba(0, 0, 0, 0.56)",
    position: "fixed",
    top: 10,
    right: 10,
    width: "220px",
    textAlign: "start",
    boxShadow: " 0 0 6px -3px #000",
    borderRadius: 15,
    padding: "0 15px 5px 15px ",
    zIndex: 22,
  },
  title: {
    fontSize: "22px",
    textAlign: "center",
    fontWeight: "bold",
  },
  abr: {
    fontSize: "14px",
    fontWeight: "bold",
    margin: "10px 0 ",
    textTransform: "capitalize",
  },
};

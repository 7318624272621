import img1 from "../assets/images/1.svg";
import img2 from "../assets/images/2.svg";
import img3 from "../assets/images/3.svg";
import img4 from "../assets/images/4.svg";
import img5 from "../assets/images/5.svg";
import img6 from "../assets/images/6.svg";
import img7 from "../assets/images/7.svg";
import img8 from "../assets/images/8.svg";
import img9 from "../assets/images/9.svg";
import img10 from "../assets/images/10.svg";
import img11 from "../assets/images/11.svg";
import img12 from "../assets/images/12.svg";
import img13 from "../assets/images/13.svg";
import img14 from "../assets/images/14.svg";
import img15 from "../assets/images/15.svg";
import img16 from "../assets/images/16.svg";
interface ImageProps {
  [key: string]: any;
}
export const IMAGES: ImageProps = {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
};

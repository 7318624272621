import React, { useState } from "react";
import { Group, Line, Transformer } from "react-konva";
import { DeleteIc } from "../data/Icons";
import { Fab } from "@mui/material";
import { Html } from "react-konva-utils";
import ColorPicker from "./ColorPicker";

const StrightLine = (props: any) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  const lineRef = React.useRef();
  const [deleteItem, setDeleteItem] = useState(false);
  const [color, setColor] = useState("#000");

  React.useEffect(() => {
    if (props.isSelected) {
      // @ts-ignore
      trRef.current.nodes([shapeRef.current]);
      // @ts-ignore
      trRef.current.getLayer().batchDraw();
    }
  }, [props.isSelected]);

  React.useEffect(() => {
    // @ts-ignore
    lineRef.current.attrs.stroke = color;
    // @ts-ignore
    lineRef.current.getLayer().batchDraw();
  }, [color]);

  return (
    <>
      <React.Fragment>
        <Group
          // @ts-ignore
          ref={shapeRef}
          onDragStart={props.onSelect}
          onMouseEnter={(e) => {
            // @ts-ignore
            const container = e.target.getStage().container();
            container.style.cursor = "move";
          }}
          onMouseLeave={(e) => {
            // @ts-ignore
            const container = e.target.getStage().container();
            if (container.style.cursor === "move") {
              container.style.cursor = "default";
            }
          }}
          visible={!deleteItem}
          onClick={props.onSelect}
          draggable
        >
          <Line {...props.shapeProps} strokeWidth={2} ref={lineRef} />
          <Line {...props.shapeProps} stroke="#00000000" strokeWidth={20} />
        </Group>
        {props.isSelected && !deleteItem && (
          <Transformer
            enabledAnchors={[
              props.shapeProps.points[3] !== props.shapeProps.points[1]
                ? "bottom-center"
                : "middle-left",
              props.shapeProps.points[3] !== props.shapeProps.points[1]
                ? "top-center"
                : "middle-right",
            ]}
            rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315, 360]}
            flipEnabled={false}
            draggable
            shouldOverdrawWholeArea={true}
            // @ts-ignore
            ref={trRef}
            boundBoxFunc={(oldBox, newBox) => {
              if (
                props.shapeProps.points[3] !== props.shapeProps.points[1] &&
                newBox.width > 27
              ) {
                return oldBox;
              } else if (
                props.shapeProps.points[3] !== props.shapeProps.points[1] &&
                newBox.height > 27
              ) {
              }
              return newBox;
            }}
          />
        )}
      </React.Fragment>
      <Html
        divProps={{
          style: {
            position: "fixed",
          },
        }}
      >
        <div
          style={{
            ...styles.fabBottom,
            display: props.isSelected && !deleteItem ? "flex" : "none",
          }}
        >
          <Fab
            color="secondary"
            size="medium"
            style={styles.fabDelete}
            aria-label="Delete"
            onClick={() => setDeleteItem(true)}
          >
            <DeleteIc />
          </Fab>
          <ColorPicker
            onPick={(color: string) => {
              setColor(color);
            }}
          />
        </div>
      </Html>
    </>
  );
};
export default StrightLine;

interface StyleProps {
  [key: string]: React.CSSProperties;
}

const styles: StyleProps = {
  container: {
    height: "80px",
    width: "80px",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 15,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  fabBottom: {
    position: "relative",
    top: 30,
    left: "600px",
  },
  fabDelete: {
    marginRight: "20px",
    backgroundColor: "#FA5352",
  },
};

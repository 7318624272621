import { Fab } from "@mui/material";
import React, { useState } from "react";
import { Image, Transformer } from "react-konva";
import { Html } from "react-konva-utils";
import useImage from "use-image";
import { DeleteIc } from "../data/Icons";

const Item = (props: {
  img: string;
  shapeProps: any;
  isSelected: any;
  onSelect: any;
}) => {
  const [image] = useImage(props.img);
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  const [deleteItem, setDeleteItem] = useState(false);
  React.useEffect(() => {
    if (props.isSelected && !deleteItem) {
      // @ts-ignore
      trRef.current.nodes([shapeRef.current]);
      // @ts-ignore
      trRef.current.getLayer().batchDraw();
    }
  }, [props.isSelected, deleteItem]);

  return (
    <>
      <React.Fragment>
        <Image
          draggable
          onClick={props.onSelect}
          onDragStart={props.onSelect}
          {...props.shapeProps}
          image={image}
          onMouseEnter={(e) => {
            // @ts-ignore
            const container = e.target.getStage().container();
            container.style.cursor = "move";
          }}
          onMouseLeave={(e) => {
            // @ts-ignore
            const container = e.target.getStage().container();
            if (container.style.cursor === "move") {
              container.style.cursor = "default";
            }
          }}
          visible={!deleteItem}
          strokeWidth={2}
          scale={{ x: 2, y: 2 }}
          onTransformEnd={(e) => {
            const node = shapeRef.current;
            // @ts-ignore
            const scaleX = node.scaleX();
            // @ts-ignore
            const scaleY = node.scaleY();
            // @ts-ignore
            node.scaleX(2);
            // @ts-ignore
            node.scaleY(2);
            // @ts-ignore
            node.width((node.width() * scaleX) / 2);
            // @ts-ignore
            node.height((node.height() * scaleY) / 2);
            // @ts-ignore
          }}
          onDragMove={(e) => {
            const node = shapeRef.current;
            // @ts-ignore
            // console.log(node);
            node.x(Math.round(e.evt.x / 20) * 20);
            // @ts-ignore
            node.y(Math.round(e.evt.y / 20) * 20);
          }}
          // @ts-ignore
          ref={shapeRef}
        />

        {props.isSelected && !deleteItem && (
          <Transformer
            flipEnabled={false}
            enabledAnchors={["top-left", "bottom-right"]}
            // @ts-ignore

            ref={trRef}
            rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315, 360]}
            boundBoxFunc={(oldBox, newBox) => {
              newBox.width = Math.max(30, newBox.width);
              return newBox;
            }}
          />
        )}
      </React.Fragment>
      <Html
        divProps={{
          style: {
            position: "fixed",
          },
        }}
      >
        <div
          style={{
            ...styles.fabBottom,
            display: props.isSelected && !deleteItem ? "flex" : "none",
          }}
        >
          <Fab
            color="secondary"
            size="medium"
            style={styles.fabDelete}
            aria-label="Delete"
            onClick={() => setDeleteItem(true)}
          >
            <DeleteIc />
          </Fab>
        </div>
      </Html>
    </>
  );
};

export default Item;

interface StyleProps {
  [key: string]: React.CSSProperties;
}

const styles: StyleProps = {
  container: {
    height: "80px",
    width: "80px",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 15,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  fabBottom: {
    position: "relative",
    top: 30,
    left: "532px",
  },
  fabDelete: {
    marginRight: "20px",
    backgroundColor: "#FA5352",
  },
};

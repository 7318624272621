import React, { useState } from "react";
import { Text, Transformer } from "react-konva";
import { DeleteIc } from "../data/Icons";
import { Fab, Input } from "@mui/material";
import { Html } from "react-konva-utils";

const TextR = (props: any) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  const inputRef = React.useRef();
  const [deleteItem, setDeleteItem] = useState(false);
  const valtext = React.useRef("Text Here");

  React.useEffect(() => {
    if (props.isSelected && !deleteItem) {
      // @ts-ignore
      trRef.current.nodes([shapeRef.current]);
      // @ts-ignore
      trRef.current.getLayer().batchDraw();
    } else if (!deleteItem) {
      // @ts-ignore
      shapeRef.current.show();
      // @ts-ignore
      shapeRef.current.attrs.text = valtext.current;
      // @ts-ignore
      inputRef.current.style.display = "none";
    }
  }, [props.isSelected, deleteItem]);

  return (
    <>
      <React.Fragment>
        <Text
          onClick={props.onSelect}
          onDragStart={props.onSelect}
          {...props.shapeProps}
          onMouseEnter={(e) => {
            // @ts-ignore
            const container = e.target.getStage().container();
            container.style.cursor = "move";
          }}
          onMouseLeave={(e) => {
            // @ts-ignore
            const container = e.target.getStage().container();
            if (container.style.cursor === "move") {
              container.style.cursor = "default";
            }
          }}
          onDragMove={(e) => {
            // const node = shapeRef.current;
            // @ts-ignore
            // node.x(Math.round(e.evt.x / 20) * 20);
            // @ts-ignore
            // node.y(Math.round(e.evt.y / 20) * 20);
          }}
          onDblClick={() => {
            // @ts-ignore
            shapeRef.current.hide();
            // @ts-ignore
            trRef.current.hide();
            // @ts-ignore
            inputRef.current.style.display = "flex";
            // @ts-ignore
            inputRef.current.style.left = `${shapeRef.current.attrs.x}px`;
            // @ts-ignore
            inputRef.current.style.top = `${shapeRef.current.attrs.y}px`;
          }}
          visible={!deleteItem}
          strokeWidth={2}
          onTransform={(e) => {
            const node = shapeRef.current;
            // @ts-ignore
            node.width(node.width() * node.scaleX());
            // @ts-ignore
            node.scaleX(1);
          }}
          // @ts-ignore
          ref={shapeRef}
          text={valtext.current}
          draggable
          fontSize={22}
        />
        {props.isSelected && !deleteItem && (
          <Transformer
            flipEnabled={false}
            enabledAnchors={["middle-left", "middle-right"]}
            // @ts-ignore

            ref={trRef}
            rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315, 360]}
            boundBoxFunc={(oldBox, newBox) => {
              newBox.width = Math.max(30, newBox.width);
              return newBox;
            }}
          />
        )}
      </React.Fragment>
      <Html
        divProps={{
          style: {
            position: "fixed",
          },
        }}
      >
        <div
          style={{
            ...styles.fabBottom,
            display: props.isSelected && !deleteItem ? "flex" : "none",
          }}
        >
          <Input
            ref={inputRef}
            defaultValue={valtext.current}
            style={{
              position: "fixed",
              width: trRef.current
                ? // @ts-ignore
                  `${trRef.current.attrs.width}px`
                : "max-content",
              height: "auto",
              fontSize: "22px",
              lineHeight: 1,
            }}
            onChange={(e) => {
              valtext.current = e.target.value;
            }}
            multiline
            disableUnderline
          />

          <Fab
            color="secondary"
            size="medium"
            style={styles.fabDelete}
            aria-label="Delete"
            onClick={() => setDeleteItem(true)}
          >
            <DeleteIc />
          </Fab>
        </div>
      </Html>
    </>
  );
};
export default TextR;

interface StyleProps {
  [key: string]: React.CSSProperties;
}

const styles: StyleProps = {
  container: {
    height: "80px",
    width: "80px",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 15,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  fabBottom: {
    position: "relative",
    top: 30,
    left: "532px",
  },
  fabDelete: {
    marginRight: "20px",
    backgroundColor: "#FA5352",
  },
};

import React from "react";
import { IMAGES } from "../data/Images";
import ItemFrame from "./ItemFrame";
import logo from "../assets/images/logo.png";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

function SideList(props: { setSelectedItem: (arg0: any) => any }) {
  const renderList = () => {
    return Array(17)
      .fill({})
      .map(
        (_, i) =>
          !!i && (
            <ItemFrame
              onSelect={(id: any) => props.setSelectedItem(id)}
              key={i}
              id={i}
              img={IMAGES[`img${i}`]}
            />
          )
      );
  };
  return (
    <SimpleBar style={styles.scroll}>
      <div style={styles.container}>
        <img src={logo} alt="" style={styles.logo} />
        <h2 style={styles.title}>Circuit Lab</h2>
        <div style={styles.list}>{renderList()}</div>
      </div>
    </SimpleBar>
  );
}

export default SideList;

interface StyleProps {
  [key: string]: React.CSSProperties;
}

const styles: StyleProps = {
  scroll: {
    width: "240px",
    height: "100vh",
    background: "white",
    borderRadius: " 0 15px",
  },
  logo: {
    margin: "20px auto   10px auto",
    position: "sticky",
  },
  container: {
    height: "100%",
    alignItems: "center",
    justifyItems: "center",
    zIndex: 10,
    position: "relative",
    borderRadius: " 0 15px",
    backgroundColor: "#f7f7f6",
  },
  list: {
    display: "grid",
    gridTemplateColumns: " repeat(2, 1fr)",
    gridAutoRows: "120px",
    alignItems: "center",
    justifyItems: "center",
    borderTop: "1px solid #e0e3e6 ",
    backgroundColor: "#f2f2f4",
  },
  title: {
    color: "rgba(0, 0, 0, 0.56)",
    fontSize: "18px",
    fontWeight: "bold",
    textTransform: "uppercase",
    margin: 0,
    padding: "10px",
    paddingTop: "10px",
    borderTop: "1px solid #e0e3e6 ",
  },
};

import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
function ColorPicker(props: ColorPickerTypes) {
  const [color, setColor] = React.useState("left");

  const handleAlignment = (
    _event: any,
    newAlignment: React.SetStateAction<string>
  ) => {
    setColor(newAlignment);
    props.onPick(newAlignment);
  };

  return (
    <div>
      <ToggleButtonGroup
        value={color}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        <ToggleButton
          style={styles.toggle}
          value="#007C05"
          aria-label="left aligned"
        >
          <ColorImg color="#007C05" />
        </ToggleButton>
        <ToggleButton
          style={styles.toggle}
          value="#ff2c2c"
          aria-label="centered"
        >
          <ColorImg color="#ff2c2c" />
        </ToggleButton>
        <ToggleButton
          style={styles.toggle}
          value="#f7e928"
          aria-label="right aligned"
        >
          <ColorImg color="#f7e928" />
        </ToggleButton>
        <ToggleButton
          style={styles.toggle}
          value="#fe8a54"
          aria-label="right aligned"
        >
          <ColorImg color="#fe8a54" />
        </ToggleButton>
        <ToggleButton
          style={styles.toggle}
          value="#2956c2"
          aria-label="right aligned"
        >
          <ColorImg color="#2956c2" />
        </ToggleButton>
        <ToggleButton
          style={styles.toggle}
          value="#757983"
          aria-label="right aligned"
        >
          <ColorImg color="#757983" />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

interface ColorPickerTypes {
  onPick: any;
}

const ColorImg = (props: any) => {
  return (
    <div
      style={{
        width: 40,
        height: 40,
        backgroundColor: props.color,
        borderRadius: 25,
      }}
    />
  );
};

export default ColorPicker;

interface StyleProps {
  [key: string]: React.CSSProperties;
}

const styles: StyleProps = {
  toggle: {
    padding: "5px ",
    borderRadius: 25,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    marginRight: "15px  ",
  },
};

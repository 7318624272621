import { Fab } from "@mui/material";
import jsPDF from "jspdf";
import React, { useRef, useState } from "react";
import { Stage, Layer, Line, Group } from "react-konva";
import { Html } from "react-konva-utils";
import { DeleteIc, EraserIc, PenIc } from "../data/Icons";
import { IMAGES } from "../data/Images";
import Item from "./Item";
import StrightLine from "./StraightLine";
import TextR from "./TextR";
export default function DrawLine(props: any) {
  const [tool, setTool] = useState("pen");
  const [ctrlDown, setCtrlDown] = useState(false);
  const isDrawingLine = useRef(false);
  const isDrawingStraightLine = useRef(false);
  const isDrawingText = useRef(false);
  const isDrawingItem = useRef(false);
  const lineRef = useRef();
  const stageRef = useRef(null);
  const linePoints = useRef([0, 0, 0, 0]);
  const textPoints = useRef([0, 0, 0, 0]);
  const itemPoints = useRef([0, 0]);
  const [lines, setLines] = useState([]);
  const [straightLines, setStraightLines] = useState([]);
  const [texts, setTexts] = useState([]);
  const [items, setItems] = useState([]);
  const [pointsLine, setPointsLine] = useState([0, 0, 0, 0]);
  const [selected, setSelected] = useState(-1);
  const width = window.innerWidth;
  const height = window.innerHeight;

  React.useEffect(() => {
    setSelected(-1);
  }, [props.draw]);

  React.useEffect(() => {
    if (props.exportImage) {
      handleExport();
    } else if (props.drawItem !== -1) {
      isDrawingItem.current = true;
    } else {
      document.body.style.cursor = "default";
    }
  }, [props.exportImage, props.drawItem]);

  const handleExport = () => {
    var doc = new jsPDF("l", "px", "a4");
    var widthDoc = doc.internal.pageSize.getWidth();
    var ratioW = widthDoc / width;
    // @ts-ignore
    const uri = stageRef.current.toDataURL({
      quality: 1,
      pixelRatio: 2,
    });
    doc.setFontSize(40);
    doc.addImage(uri, "PNG", 0, 0, widthDoc, ratioW * height);
    doc.save("two-by-four.pdf");
    props.download(doc);
  };

  const checkDeselect = (e: { target: { getStage: () => any } }) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelected(-1);
    }
  };

  const handleMouseDownLine = (e: any) => {
    checkDeselect(e);
    isDrawingLine.current = true;
    const pos = e.target.getStage().getPointerPosition();
    //@ts-ignore
    setLines([...lines, { tool, points: [pos.x, pos.y] }]);
  };

  const handleMouseMoveLine = (e: { target: { getStage: () => any } }) => {
    if (!isDrawingLine.current || !props.draw) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    //@ts-ignore
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUpLine = () => {
    isDrawingLine.current = false;
  };

  const handleMouseDownStraightLine = (e: any) => {
    checkDeselect(e);
    if (!props.drawStrightLine) {
      return;
    }
    isDrawingStraightLine.current = true;
    const pos = e.target.getStage().getPointerPosition();
    linePoints.current = [pos.x, pos.y, pos.x, pos.y];
  };

  const handleMouseUpStraightLine = (e: {
    target: { getStage: () => any };
  }) => {
    if (!isDrawingStraightLine.current || !props.drawStrightLine) {
      return;
    }

    // @ts-ignore
    setStraightLines((lines) => [
      ...lines,
      {
        id: `line${lines.length}`,
        linePoints: linePoints.current,
      },
    ]);
    setPointsLine([0, 0, 0, 0]);
    props.onDone();
    isDrawingStraightLine.current = false;
  };

  const handleMouseMoveStraightLine = (e: {
    target: { getStage: () => any };
    type: string;
  }) => {
    if (!isDrawingStraightLine.current || !props.drawStrightLine) {
      return;
    }

    const point = e.target.getStage().getPointerPosition();
    if (props.drawStrightLine === 1) {
      linePoints.current = [
        linePoints.current[0],
        linePoints.current[1],
        point.x,
        linePoints.current[1],
      ];
    } else {
      linePoints.current = [
        linePoints.current[0],
        linePoints.current[1],
        linePoints.current[0],
        point.y,
      ];
    }
    setPointsLine(linePoints.current);
  };

  const handleMouseDownText = (e: any) => {
    checkDeselect(e);
    if (!props.text) {
      return;
    }
    isDrawingText.current = true;
    const pos = e.target.getStage().getPointerPosition();
    textPoints.current = [pos.x, pos.y];
  };

  const handleMouseUpText = (e: { target: { getStage: () => any } }) => {
    if (!isDrawingText.current || !props.text) {
      return;
    }
    // @ts-ignore
    setTexts((texts) => [
      ...texts,
      {
        id: `text${texts.length}`,
        textPoints: textPoints.current,
      },
    ]);
    props.onDone();
    isDrawingText.current = false;
  };

  const handleMouseDownItem = (e: any) => {
    checkDeselect(e);
    if (props.drawItem === -1) {
      return;
    }
    isDrawingItem.current = true;
    const pos = e.target.getStage().getPointerPosition();
    itemPoints.current = [pos.x, pos.y];
  };

  const handleMouseMoveItem = (e: any) => {
    const container = e.target.getStage().container();
    if (isDrawingItem.current === false || props.drawItem === -1) {
      container.style.cursor = `default`;
      document.body.style.cursor = "default";
      return;
    }
    isDrawingItem.current = true;
    container.style.cursor = `url(${IMAGES[`img${props.drawItem}`]}) , auto`;
    document.body.style.cursor = `url(${
      IMAGES[`img${props.drawItem}`]
    }) , auto`;
  };

  const handleMouseUpItem = (e: { target: { getStage: () => any } }) => {
    if (!isDrawingItem.current || props.drawItem === -1) {
      return;
    }
    // @ts-ignore
    setItems((items) => [
      ...items,
      {
        id: `item${items.length}`,
        img: `img${props.drawItem}`,
        textPoints: itemPoints.current,
      },
    ]);
    props.onDone();
    const container = e.target.getStage().container();
    container.style.cursor = `default`;
    document.body.style.cursor = "default";
    isDrawingItem.current = false;
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Control") {
      setCtrlDown(true);
    }
  };

  const handleKeyUp = (e: any) => {
    if (e.key === "Control") {
      setCtrlDown(true);
    }
  };

  return (
    <div
      tabIndex={1}
      // onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}
    >
      <Stage
        // @ts-ignore
        ref={stageRef}
        width={window.innerWidth}
        height={window.innerHeight}
        style={{
          position: "absolute",
          backgroundImage:
            "linear-gradient(to right, #f0f0f0 1px, transparent 1px),linear-gradient(to bottom, #f0f0f0 1px, transparent 1px)",
          // "radial-gradient(closest-side, #aaa9ac 8%, transparent 8%) , radial-gradient(closest-side, #aaa9ac 8%, transparent 8%)",
          backgroundSize: "20px 20px",
          // backgroundColor: "#f6f5f8",
        }}
        onMouseOver={(e) => {
          // @ts-ignore
          const container = e.target.getStage().container();
          container.style.cursor = `default`;
          document.body.style.cursor = "default";
        }}
        onMouseDown={(e) => {
          if (props.drawStrightLine) {
            handleMouseDownStraightLine(e);
          } else if (props.draw) {
            handleMouseDownLine(e);
          } else if (props.text) {
            handleMouseDownText(e);
          } else if (props.drawItem !== -1) {
            handleMouseDownItem(e);
          } else {
            checkDeselect(e);
          }
        }}
        onMousemove={(e: {
          target: { getStage: () => any } | { getStage: () => any };
          type: string;
        }) => {
          if (props.drawStrightLine) {
            handleMouseMoveStraightLine(e);
          } else if (props.draw) {
            handleMouseMoveLine(e);
          } else if (props.text) {
            // handleMouseMoveText(e);
          } else if (props.drawItem !== -1) {
            handleMouseMoveItem(e);
          } else {
            // checkDeselect(e);
          }
        }}
        onMouseup={(e: { target: { getStage: () => any } }) => {
          if (props.drawStrightLine) {
            handleMouseUpStraightLine(e);
          } else if (props.draw) {
            handleMouseUpLine();
          } else if (props.text) {
            handleMouseUpText(e);
          } else if (props.drawItem !== -1) {
            handleMouseUpItem(e);
          }
        }}
      >
        <Layer>
          <Html
            divProps={{
              style: {
                position: "fixed",
              },
            }}
          >
            <div
              style={{
                ...styles.fabBottom,
                display:
                  lines.length > 0 ||
                  straightLines.length > 0 ||
                  texts.length > 0 ||
                  items.length > 0
                    ? "flex"
                    : "none",
              }}
            >
              <Fab
                color="secondary"
                size="medium"
                style={styles.fabDelete}
                aria-label="Delete"
                onClick={() => {
                  setLines([]);
                  setStraightLines([]);
                  setTexts([]);
                  setItems([]);
                }}
              >
                <DeleteIc />
              </Fab>
            </div>
          </Html>
          <Group>
            {lines.map((line, i) => (
              <Line
                key={i}
                //@ts-ignore
                ref={lineRef}
                //@ts-ignore
                points={line.points}
                stroke="#000"
                strokeWidth={
                  //@ts-ignore
                  line.tool === "eraser" ? 30 : 2
                }
                tension={0.5}
                lineCap="round"
                globalCompositeOperation={
                  //@ts-ignore
                  line.tool === "eraser" ? "destination-out" : "source-over"
                }
              />
            ))}
            <Html
              divProps={{
                style: {
                  position: "fixed",
                },
              }}
            >
              <div
                style={{
                  ...styles.fabLine,
                  display: props.draw ? "flex" : "none",
                }}
              >
                <Fab
                  color="secondary"
                  size="medium"
                  style={{
                    ...styles.fabDelete,
                    background: tool !== "eraser" ? "#FA5352" : "#46B4FA",
                  }}
                  aria-label="Delete"
                  onClick={() => {
                    document.body.style.cursor =
                      tool !== "eraser" ? "grab" : "default";
                    //@ts-ignore
                    setTool((tool) => (tool === "eraser" ? "pen" : "eraser"));
                  }}
                >
                  {tool === "pen" && <EraserIc />}
                  {tool === "eraser" && <PenIc />}
                </Fab>
              </div>
            </Html>

            {straightLines.map((line, i) => {
              return (
                <StrightLine
                  key={i}
                  shapeProps={{
                    // @ts-ignore
                    points: line.linePoints,
                    stroke: "black",
                    // @ts-ignore
                    width: line.width,
                  }}
                  // @ts-ignore
                  isSelected={line.id === selected}
                  onSelect={() => {
                    // @ts-ignore
                    setSelected(line.id);
                  }}
                />
              );
            })}

            <StrightLine
              key={-2}
              shapeProps={{ points: pointsLine, stroke: "black" }}
              isSelected={false}
              onSelect={undefined}
              onChange={(newAttrs: any) => {}}
            />

            {texts.map((text, i) => {
              return (
                <TextR
                  key={i}
                  // @ts-ignore
                  onChange={(newAttrs) => {
                    const textArray = texts.slice();
                    // @ts-ignore
                    textArray[i] = newAttrs;
                    setTexts((texts) => textArray);
                  }}
                  shapeProps={{
                    // @ts-ignore
                    x: text.textPoints[0],
                    // @ts-ignore
                    y: text.textPoints[1],
                    // @ts-ignore
                    id: text.id,
                    // @ts-ignore
                    width: text.width,
                  }}
                  // @ts-ignore
                  isSelected={text.id === selected}
                  onSelect={() => {
                    // @ts-ignore
                    setSelected(text.id);
                  }}
                />
              );
            })}

            {items.map((item, i) => {
              return (
                <Item
                  key={i}
                  shapeProps={{
                    // @ts-ignore
                    x: item.textPoints[0],
                    // @ts-ignore
                    y: item.textPoints[1],
                  }}
                  // @ts-ignore
                  img={IMAGES[`${item.img}`]}
                  // @ts-ignore
                  isSelected={item.id === selected}
                  onSelect={() => {
                    // @ts-ignore
                    setSelected(item.id);
                  }}
                />
              );
            })}
          </Group>
        </Layer>
      </Stage>
    </div>
  );
}

interface StyleProps {
  [key: string]: React.CSSProperties;
}

const styles: StyleProps = {
  container: {
    height: "80px",
    width: "80px",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 15,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  fabBottom: {
    position: "relative",
    top: 30,
    left: "600px",
  },
  fabLine: {
    position: "relative",
    top: 30,
    left: "532px",
  },
  fabDelete: {
    marginRight: "20px",
    backgroundColor: "#FA5352",
  },
};

import React from "react";
import cursor from "../assets/images/eraser.png";

interface ItemFrameProps {
  img: string;
  id: any;
  onSelect: any;
}

export default function ItemFrame(props: ItemFrameProps) {
  return (
    <div
      style={{
        ...styles.container,
        ...{
          userSelect: "none",
          backgroundImage: `url(${props.img})`,
        },
      }}
      draggable={true}
      onClick={(event) => {
        props.onSelect(props.id);
        document.body.style.cursor = `url(${props.img}) , auto`;
      }}
      onDragStart={(event) => {
        props.onSelect(props.id);
        document.body.style.cursor = `url(${props.img}) , auto`;
        event.preventDefault();
        event.stopPropagation();
      }}
    />
  );
}

interface StyleProps {
  [key: string]: React.CSSProperties;
}

const styles: StyleProps = {
  container: {
    height: "80px",
    width: "80px",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: 15,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
};

import UndoIcon from "@mui/icons-material/Undo";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import GestureOutlinedIcon from "@mui/icons-material/GestureOutlined";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import BrushIcon from "@mui/icons-material/Brush";
import { ReactComponent as Eraser } from "../assets/images/eraser.svg";
export const UndoIc = () => <UndoIcon />;
export const LineVIc = () => <HorizontalRuleIcon />;
export const LineHIc = () => (
  <div
    style={{
      justifyContent: "center",
      display: "flex",
      transform: "rotate(90deg)",
    }}
  >
    <HorizontalRuleIcon />
  </div>
);
export const TextIc = () => <TextFieldsIcon />;
export const DrawIc = () => <GestureOutlinedIcon />;
export const DeleteIc = () => <DeleteIcon />;
export const PdfIc = () => <FileDownloadOutlinedIcon />;
export const FlipIc = () => <Rotate90DegreesCcwIcon />;
export const EraserIc = () => <Eraser />;
export const PenIc = () => <BrushIcon />;
